<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Report</h4>
          </template>
          <template v-slot:headerAction>
            <div style="margin-right: 800px;">
               <b-dropdown text="Load Selection" variant="primary">
                 <b-dropdown-item 
                     v-for="(item, index) in query_rows" 
                     :key="index" 
                     @click="handleItemClick(item)"
                 >
                  {{ item.name }}
                 </b-dropdown-item> 
               </b-dropdown>
            </div>
          </template>

          <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
            <b-row>
                  <b-col md="5">
                    <div style="border: 1px solid #d7dbda !important;padding: 3%;">
            <label for="date_range">Date Range</label>
          <date-range-picker
                      :opens="opens"  
                        :locale-data="localeData"
                        v-model="dateRange"
                        :show-dropdowns="true"  
                        :auto-apply="true"
                        style="width:100%;"
                      >
                </date-range-picker>
                <label for="date_range">Timezone</label>
                <multiselect
                v-model="items.timezone"
                :options="date_timezone"
                :multiple="false"
                :clear-on-select="false"
                :preserve-search="true"
                label="label"
                track-by="value"
                />
                <label for="date_range">View BY</label>
                <multiselect
                v-model="items.view_by" 
                :options="view_by"
                :multiple="false"
                :clear-on-select="false"
                :preserve-search="true"
                label="label"
                track-by="value"
                />
                <label for="date_range">Group By</label>
                <multiselect
                v-model="items.group_by"
                :options="group_by"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                label="label"
                track-by="value"
                />
              </div>
              </b-col>
                <b-col md="7">
                  <div style="border: 1px solid #d7dbda !important;padding:3%;">
                    <b-row>
                  <b-col>
                    <label for="Filter">Filter</label>
                  </b-col>
                  <b-col class="text-right">
                    <span class="text-danger" style="cursor: pointer;" @click="clearFilter()">Clear Filter</span>
                  </b-col>
                </b-row>
            <div v-for="(input, index) in items.filter" :key="`filterInput-${index}`"
                  class="input wrapper flex items-center">
                  <div class="row">
                    <div class="col-md-4">
                      <multiselect
                        v-model="input.key" 
                        :options="keyData['keyField_' + index ]"
                        :multiple="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        label="label"
                        track-by="value"
                        @input="onChange($event, index)"
                        class="filter"
                        
                      />
                  </div>
                  <div class="col-md-1">
                    <span style="font-weight: 800;font-size: 20px;"><b>=</b></span>
                  </div>
                  <div class="col-md-5">
                    <multiselect
                        v-model="input.value" 
                        :options="valueData['valueField_' + index ]"
                        :multiple="true"
                        :taggable="true"
                        :clear-on-select="false"
                        :preserve-search="true"
                        label="label"
                        track-by="value"
                        @tag="addTag($event, index)"
                        class="filter"
                      />
                  </div>
                  <!--          Add Svg Icon-->
                  <svg @click="insertField(input, items.filter, index)" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path fill="green"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                  </svg>

                  <!--          Remove Svg Icon-->
                  <svg v-show="items.filter.length > 1" @click="removeField(index, items.filter)"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                    class="ml-2 cursor-pointer">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path fill="#EC4899"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                  </svg>
                </div>
                </div>
          </div>
            </b-col>
            </b-row>
            <br/><br/>
            <b-button type="submit" variant="primary">Submit</b-button>
            <template >
            <vue-csv-downloader 
                class="ml-md-4"
                :data="rowsData"
                :fields="fieldColumns"
                name="my_data.csv" >
                <b-button variant="primary">Download Report</b-button>
              </vue-csv-downloader>
              </template>
            <template>
              <b-button  @click="showModal" variant="primary"  class="ml-md-4">Save Selection</b-button>
              <b-modal ref="myModalRef" id="modal-4" title="Save Query" hide-footer>
                <form class="mt-4" @submit.prevent="storeData">
                  <div class="row align-items-center">
                     <div class="col-md-8">
                           <b-form-group label="Query Title" label-for="name">
                               <b-form-input id="name" type="text" v-model="items.name"></b-form-input>
                           </b-form-group>
                     </div>
                     <div class="col-md-4 mt-4">
                        <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
                     </div>
                  </div>
                </form>
            <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-table bordered hover 
                :items="query_rows" 
                :fields="query_columns"
                stacked="md"
                show-empty
                small
                >
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
              </b-modal>
            </template>
              </form>
              <br/><br/>
              <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">

    </b-col>
              <b-table bordered hover 
              :items="rowsData" 
              :fields="columns"
              stacked="md"
              show-empty
              small
              @sort-changed="handleSort"
              > 
              <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key" >
                  <template >
                    <b-form-input v-model="filters[field.key]"  @input="filterRows"></b-form-input>
                  </template>
                  </td>
                </template>
                <template v-slot:cell(offer)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.offer">
                           {{ data.item.offer }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(clickid)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.clickid">
                           {{ data.item.clickid }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub1)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub1">
                           {{ data.item.sub1 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub2)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub2">
                           {{ data.item.sub2 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub3)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub3">
                           {{ data.item.sub3 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub4)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub4">
                           {{ data.item.sub4 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub5)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub5">
                           {{ data.item.sub5 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub6)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub6">
                           {{ data.item.sub6 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub7)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub7">
                           {{ data.item.sub7 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub8)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub8">
                           {{ data.item.sub8 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub9)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub9">
                           {{ data.item.sub9 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub10)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub10">
                           {{ data.item.sub10 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub11)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub11">
                           {{ data.item.sub11 }}
                          </div>
                        </span>
                </template>
                <template v-slot:cell(sub12)="data">
                        <span class="res_text">
                          <div class="text-left" :title="data.item.sub12">
                           {{ data.item.sub12 }}
                          </div>
                        </span>
                </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
  </template> 
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Offer from '../../Apis/offer'
import Common from '../../Apis/common'
import VueCsvDownloader from 'vue-csv-downloader'
import DateRangePicker from 'vue2-daterange-picker'
import Multiselect from 'vue-multiselect'
import Loader  from '../../components/Loader.vue'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
    this.loadingData=false;
    this.getReportDetails()
    this.getHistoryData()
    this.submitForm()
  },
  components: {
    DateRangePicker,Multiselect,Loader,VueCsvDownloader
  },
  data () {
    return {
      dateRange:{
        startDate: new Date(),
        endDate: new Date()
      },
      filters:[],
      query_rows:[],
      items: {
        timezone: { value:  'Asia/Calcutta' , label:'(GMT+05:30) India Standard Time'},
        view_by:{value:'daily',label:'Daily'},
        group_by:[{ value :"eventid", label:"Event"},{ value :"offer_id", label:"Offer"}],
        filter:[{key:[],value:[]}]
      },
      opens: 'right',
      localeData:{
          direction: 'ltr',
          format: 'yyyy/mm/dd',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0
      },
      date_timezone:[
      { value: 'Pacific/Pago_Pago' , label: '(GMT-11:00) Pago Pago'},
      { value:  'Pacific/H`onolulu' , label: '(GMT-10:00) Hawaii Time'},
      { value:  'Pacific/Tahiti' , label: '(GMT-10:00) Tahiti'},
      { value:  'America/Anchorage' , label: '(GMT-09:00) Alaska Time'},
      { value:  'America/Los_Angeles' , label: '(GMT-08:00) Pacific Time'},
      { value:  'America/Denver' , label:'(GMT-07:00) Mountain Time'},
      { value:  'America/Chicago' , label:'(GMT-06:00) Central Time'},
      { value:  'America/New_York' , label:'(GMT-05:00) Eastern Time'},
      { value:  'America/Halifax' , label:'(GMT-04:00) Atlantic Time - Halifax'},
      { value:  'America/Argentina/Buenos_Aires' , label:'(GMT-03:00) Buenos Aires'},
      { value:  'America/Sao_Paulo' , label:'(GMT-02:00) Sao Paulo'},
      { value:  'Atlantic/Azores' , label:'(GMT-01:00) Azores'},
      { value:  'GMT' , label:'(GMT+00:00) London'},
      { value:  'Europe/London' , label:'(GMT+01:00) London'},
      { value:  'Europe/Berlin' , label:'(GMT+01:00) Berlin'},
      { value:  'Europe/Helsinki' , label:'(GMT+02:00) Helsinki'},
      { value:  'Europe/Istanbul' , label:'(GMT+03:00) Istanbul'},
      { value:  'Asia/Dubai' , label:'(GMT+04:00) Dubai'},
      { value:  'Asia/Kabul' , label:'(GMT+04:30) Kabul'},
      { value:  'Indian/Maldives' , label:'(GMT+05:00) Maldives'},
      { value:  'Asia/Calcutta' , label:'(GMT+05:30) India Standard Time'},
      { value:  'Asia/Kathmandu' , label:'(GMT+05:45) Kathmandu'},
      { value:  'Asia/Dhaka' , label:'(GMT+06:00) Dhaka'},
      { value:  'Indian/Cocos' , label:'(GMT+06:30) Cocos'},
      { value:  'Asia/Bangkok' , label:'(GMT+07:00) Bangkok'},
      { value:  'Asia/Hong_Kong' , label:'(GMT+08:00) Hong Kong'},
      { value:  'Asia/Pyongyang' , label:'(GMT+08:30) Pyongyang'},
      { value:  'Asia/Tokyo' , label:'(GMT+09:00) Tokyo'},
      { value:  'Australia/Darwin' , label:'(GMT+09:30) Central Time - Darwin'},
      { value:  'Australia/Brisbane' , label:'(GMT+10:00) Eastern Time - Brisbane'},
      { value:  'Australia/Adelaide' , label:'(GMT+10:30) Central Time - Adelaide'},
      { value:  'Australia/Sydney' , label:'(GMT+11:00) Eastern Time - Melbourne}, Sydney'},
      { value:  'Pacific/Nauru' , label:'(GMT+12:00) Nauru'},
      { value:  'Pacific/Auckland' , label:'(GMT+13:00) Auckland'},
      { value:  'Pacific/Kiriti`mati' , label:'(GMT+14:00) Kiritimati'}
      ],
      show_selct: true,
      userRole:'',
      view_by:[
      {value:'all',label:'All'},
      {value:'hourly',label:'Hourly'},
      {value:'daily',label:'Daily'},
      {value:'monthly',label:'Monthly'},
      ],
      group_by:[
      { value :"user_id", label:"User"},
      { value :"offer_id", label:"Offer"},
      { value :"eventid", label:"Event"},
      { value :"country", label:"country"},
      { value :"clickid", label:"Clickid"},
      { value :"os", label:"os"},
      { value :"browser", label:"browser"},
      { value :"isp", label:"isp"},
      { value :"sub1", label:"Sub1"},
      { value :"sub2", label:"Sub2"},
      { value :"sub3", label:"Sub3"},
      { value :"sub4", label:"Sub4"},
      { value :"sub5", label:"Sub5"},
      { value :"sub6", label:"Sub6"},
      { value :"sub7", label:"Sub7"},
      { value :"sub8", label:"Sub8"},
      { value :"sub9", label:"Sub9"},
      { value :"sub10", label:"Sub10"},
      { value :"sub11", label:"Sub11"},
      { value :"sub12", label:"Sub12"},
      { value :"adv_price", label:"Price"}
      ],
      keyData:{
          "keyField_0":[],
      },
      valueData:{
          "valueField_0":[],
      },
      rows: [],
      rowsData:[],
      fieldColumns: [],
      totalRows: 0,
      loadingData:true,
      columns:[ { label: "Date", key: "time", sortable: true },
      {label : "Click", key: "Click", sortable: true},
      {label : "Conversion", key: "Conv", sortable: true},
      {label : "CR", key: "CR", sortable: true}
    ],
    query_columns: [
        { label: 'Title', key: 'name', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
    selectedItem: null, 
    }
  },
  methods: {
    handleSort(ctx) {
      const { sortBy, sortDesc } = ctx;

      if (!sortBy) {
        this.rowsData = this.rows; // Reset to unsorted data from rows
      } else {
        this.rowsData = this.rows.sort((a, b) => {
          let result = 0;
          if (a[sortBy] < b[sortBy]) result = -1;
          if (a[sortBy] > b[sortBy]) result = 1;
          if (sortDesc) result *= -1; // Reverse order if descending
          return result;
        });
      }
    },
    clearFilter(){
      this.items.filter = [{"key": [],"value": []}]
    },
    showModal(){
      this.$refs.myModalRef.show();
      this.getHistoryData();
    },
    default () {
      return {
        items:{
          _id:'',
        name: '',
        description: '',
        url: '',
        price: '',
        pricing_model:null,
        cap:'',
        device_type :null,
        conv_flow_id:null,
        note:'',
        active:null,
        expire_date:''
        },
        editable: false,
        allerros:{},
      }
    },
    addTag (newTag,index) {
      const tag = {
        label: newTag,
        value: newTag
      }
      this.valueData["valueField_" + index].push(tag)
      this.items.filter[index].value.push(tag);
    },
    insertField(value, fieldType, index) {
      const filter_length = this.items.filter.length;

      if (filter_length <= 5) {
        const filteredGroupBy = this.group_by.filter(option => {
          for (let i = 0; i < filter_length; i++) {
            if (this.items.filter[i].key.value === option.value) {
              console.log(this.items.filter[i].key.value);
              return false;
            }
          }
          return true;  // Return true for options that haven't matched any filter
        });
        this.$set(this.keyData, "keyField_" + filter_length, filteredGroupBy);
        this.$set(this.valueData, "valueField_" + filter_length, []);
        fieldType.push({ key: "", value: [] });
      }
    },
    filterRows() {
      this.rowsData = this.rows.filter(row => {
        return Object.keys(this.filters).every(key => {
          const filterValue = this.filters[key];
          
          if (!filterValue) {
            // If the filter value is empty or null, return true to include all rows
            return true;
          }
          if (key === 'Spent' || key === 'Click' || key === 'CR' || key === 'Conv') {
            const rowValue = parseFloat(row[key]);

            switch (true) {
              case filterValue.startsWith('<'):
                const lessThan = parseFloat(filterValue.slice(1));
                return rowValue < lessThan;
              case filterValue.startsWith('>'):
                const greaterThan = parseFloat(filterValue.slice(1));
                return rowValue > greaterThan;
              default:
                return rowValue === parseFloat(filterValue);
            }
          } else {
            return String(row[key]).toLowerCase().includes(String(this.filters[key]).toLowerCase());
          }
        });
      });
    },
    storeData() {
      const filterData = {
        name: this.items.name,
        startDate: this.formatDate(new Date(this.dateRange.startDate)),
        endDate: this.formatDate(new Date(this.dateRange.endDate)),
        timezone: this.items.timezone.value,
        view_by: this.items.view_by.value,
        group_by: this.items.group_by.map(group => group.value),
        filter: this.items.filter,
      };
      try{
        let savedQueries = JSON.parse(localStorage.getItem('history')) || [];
      savedQueries.push(filterData);
      localStorage.setItem('history', JSON.stringify(savedQueries));
      this.items.name='';
      this.getHistoryData();
      }catch(e){

      }
    },
    async getHistoryData(){
    const history = localStorage.getItem('history')
    if(history){
      let historyData = JSON.parse(history);
      let queryRows = historyData.map(item => {
          return {
            name: item.name,  
          };
        });
        this.query_rows = queryRows;
    }
  },
  async remove (item) {
      if(confirm('Are you sure to delete this Query?')){
        try{
          let history = localStorage.getItem('history');
        if (history) {
          history = JSON.parse(history);
          const updatedHistory = history.filter(historyItem => historyItem.name !== item.name);
          localStorage.setItem('history', JSON.stringify(updatedHistory));
          this.query_rows = updatedHistory.map(historyItem => ({
            name: historyItem.name,
          }));

          if (updatedHistory.length !== history.length) {
            this.$toast.info('Name deleted successfully!', {
                    position: 'top-center'
                });
          } else {
            console.log('There is a some issue');
          }
        }
        }catch(error){
        }
      }
    },
    handleItemClick(item) {
      const history = localStorage.getItem('history');
      if (history) {

        let historyData = JSON.parse(history);
        let filteredData = historyData.filter(dataItem => dataItem.name === item.name);

        if (filteredData.length > 0) {
          this.selectedItem = filteredData[0];
        this.dateRange.startDate = new Date(this.selectedItem.startDate);
      this.dateRange.endDate = new Date(this.selectedItem.endDate);
      this.items.timezone = this.date_timezone.find(tz => tz.value === this.selectedItem.timezone);
      this.items.view_by = this.view_by.find(option => option.value === this.selectedItem.view_by);
      this.items.group_by = this.selectedItem.group_by.map(groupValue => {return this.group_by.find(option => option.value === groupValue)});

      this.items.filter = this.selectedItem.filter.map((filterItem, index) => {
        // Populate key field options
        this.$set(this.keyData, `keyField_${index}`, this.group_by);

        // Populate value field options dynamically
        const valueFieldKey = `valueField_${index}`;
        this.$set(this.valueData, valueFieldKey, [
          ...filterItem.value.map(val => ({ value: val.value, label: val.label }))
        ]);
        this.onChange(filterItem.key,index);
        return {
          key: this.group_by.find(option => option.value === filterItem.key.value) || { value: filterItem.key.value, label: filterItem.key.label },
          value: filterItem.value.map(val => ({ value: val.value, label: val.label }))
        };
      })|| [];

      }
    }
    },
    removeField(index, fieldType) {
      var size = Object.keys(this.items.filter).length;
      for (let i = index; i < size; i++) {
        this.keyData["keyField_" + i] = this.keyData["keyField_" + (i + 1)];
      }
      fieldType.splice(index, 1);
    },
    async onChange(event, index) {
      let dim = event ? event.value : null;
      let set_dim = [];
      
      if (!dim) {
      this.$set(this.valueData, `valueField_${index}`, []);
      // this.items.filter[index].value = [];
      return;
    }

      try{
        let response = await Common.getDimension({'dimension':dim})
        if(response.status == 200){
          let data = response.data.data;
          if(dim == 'user_id'){
            for(const key in data){
              set_dim.push({ value: data[key]._id, label: data[key].int_id+'-'+data[key].username})
          }
        }else if(dim == 'offer_id'){
          for(const key in data){
            set_dim.push({ value: data[key]._id, label: data[key].int_id+'-'+data[key].name})
          }
        }else if(dim == 'eventid'){
          for(const key in data){
            set_dim.push({ value: data[key].name, label: data[key].name})
          }
        }else if(dim == 'country'){
          for(const key in data){
            set_dim.push({ value: data[key].alpha_2, label: data[key].name})
          }
        }else if(dim == 'os'){
          for(const key in data){
            set_dim.push({ value: data[key].name, label: data[key].name})
          }
        }else if(dim == 'browser'){
          for(const key in data){
            set_dim.push({ value: data[key].name, label: data[key].name})
          }
        }
        }
      }catch(error){
      }
      this.$set(this.valueData, "valueField_" + index, set_dim);
      // this.items.filter[index].value=[];
    },
    formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
    async submitForm(){
      this.loadingData=true;
      this.rows=[];
      this.totalRows=0;
      const transformedJson = {
        startDate: this.formatDate(new Date(this.dateRange.startDate)),
        endDate: this.formatDate(new Date(this.dateRange.endDate)),
        timezone: this.items.timezone.value,
        view_by: this.items.view_by.value,
        group_by: this.items.group_by.map(group => group.value),
        filter: this.items.filter.map(item => {
          const filterObject = {};
          if (item.key && item.key.value) {
            filterObject[item.key.value] = item.value.map(valueItem => valueItem.value);
          }
          return filterObject;
        })
      };
      const data = {
        startDate: this.formatDate(new Date(this.dateRange.startDate)),
        endDate: this.formatDate(new Date(this.dateRange.endDate)),
        timezone: this.items.timezone.value,
        view_by: this.items.view_by.value,
        group_by: this.items.group_by.map(group => group.value),
        filter: this.items.filter

      };
      try{
        localStorage.setItem('report', JSON.stringify(data))
        let response = await Common.getReport(transformedJson)
        this.loadingData=false;
        if(response.status == 200){
          this.filters=[];
          this.rows = response.data.data; 
          this.rowsData = this.rows || [];
          this.totalRows = this.rows.length;
          this.sortOptions(this.rows);
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData=false;
      }catch(error){
        this.loadingData=false;
      }
    },
    async getReportDetails(){
    const report = localStorage.getItem('report')
    if(report){
      let reportData = JSON.parse(report)
      this.dateRange.startDate = new Date(reportData.startDate);
      this.dateRange.endDate = new Date(reportData.endDate);
      this.items.timezone = this.date_timezone.find(tz => tz.value === reportData.timezone);
      this.items.view_by = this.view_by.find(option => option.value === reportData.view_by);
      this.items.group_by = reportData.group_by.map(groupValue => {return this.group_by.find(option => option.value === groupValue)});

      this.items.filter = reportData.filter.map((filterItem, index) => {
        // Populate key field options
        this.$set(this.keyData, `keyField_${index}`, this.group_by);

        // Populate value field options dynamically
        const valueFieldKey = `valueField_${index}`;
        this.$set(this.valueData, valueFieldKey, [
          ...filterItem.value.map(val => ({ value: val.value, label: val.label }))
        ]);
        return {
          key: this.group_by.find(option => option.value === filterItem.key.value) || { value: filterItem.key.value, label: filterItem.key.label },
          value: filterItem.value.map(val => ({ value: val.value, label: val.label }))
        };
      })|| [];
    }
    
  },
 
    setRole(){
      const user = localStorage.getItem('user')
      if (user) {
        let role = JSON.parse(user)
        this.userRole = role.role;
      }
      console.log(this.userRole);
      if (this.userRole == 'publisher') {
        this.group_by=this.group_by.map(item => 
          item.value == 'adv_price' ? { value: 'pub_price', label: 'Price' } : item
        );
      }
      if (this.userRole == 'admin') {
        this.group_by.push({ value: 'scrub', label: 'Scrub' });
      }
    },
    getUniqueKeys(data) {
      const keys = new Set();
      
      data.forEach(item => {
        Object.keys(item).forEach(key => {
          keys.add(key);
        });
      });

      return Array.from(keys);
    },
    getColums(){
      return[
      { label: "Date", key: "time", sortable: true },
      { label: "User", key: "username", sortable: true},
      { label: "Offer", key: "offer", sortable: true},
      { label: "Event", key: "EVENT", sortable: true},
      { label: "Country", key: "country", sortable: true},
      { label: "Clickid", key: "clickid", sortable: true},
      { label: "Isp", key: "isp", sortable: true},
      { label: "OS", key: "os", sortable: true},
      { label: "Browser", key: "browser", sortable: true},
      { label: "Sub1", key: "sub1", sortable: true},
      { label: "Sub2", key: "sub2", sortable: true},
      { label: "Sub3", key: "sub3", sortable: true},
      { label: "Sub4", key: "sub4", sortable: true},
      { label: "Sub5", key: "sub5", sortable: true},
      { label: "Sub6", key: "sub6", sortable: true},
      { label: "Sub7", key: "sub7", sortable: true},
      { label: "Sub8", key: "sub8", sortable: true},
      { label: "Sub9", key: "sub9", sortable: true},
      { label: "Sub10", key: "sub10", sortable: true},
      { label: "Sub11", key: "sub11", sortable: true},
      { label: "Sub12", key: "sub12", sortable: true},
      { label: "Price", key: "Price", sortable: true},
      {label : "Scrub", key: "scrub", sortable: true},
      {label : "Click", key: "Click", sortable: true},
      {label : "Conversion", key: "Conv", sortable: true},
      {label : "CR", key: "CR", sortable: true},
      {label : "Revenue", key: "Revenue", sortable: true},
      {label : "Spent", key: "Spent", sortable: true},]
    },
    sortOptions(value) {
      const keys =this.getUniqueKeys(value);
      const fields = this.getColums();

    const filteredOptions = fields.filter(option => keys.includes(option.key));

    // Display the filtered options with their index
    filteredOptions.forEach((option, index) => {
      this.columns[index] = option;
      //  this.columns.push(option)
    });
    this.fieldColumns = this.columns.map(column => column.key);
    },
  },
  created () {
    this.keyData["keyField_0"] = this.group_by;
    this.setRole()
  },
  computed: {
    allFieldsValid() {
          return this.items.name;
      },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter:focus-within .multiselect__content-wrapper {
    width: 300px!important;
}
</style>