<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Expired Offers</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button @click="showModal" variant="primary">Add New</b-button> -->
            <b-modal ref="myModalRef" id="modal-4" size="xl" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <b-row>
                  <b-col md="6">
                <b-form-group label="Name" label-for="name">
                  <b-form-input id="name" type="text" placeholder="Enter Name" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group label="url" label-for="url">
                  <b-form-textarea id="url" rows="4" placeholder="Url" v-model="items.url"></b-form-textarea>
                  <span v-if="allerros.url" :class="['label label-danger']">@{{ allerros.url[0] }}</span>
                </b-form-group>
                <b-form-group label="Price" label-for="price">
                  <b-form-input id="price" type="text" placeholder="Enter Price" v-model="items.price"></b-form-input>
                  <span v-if="allerros.price" :class="['label label-danger']">@{{ allerros.price[0] }}</span>
                </b-form-group>
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea id="description" rows="4" placeholder="Description" v-model="items.description"></b-form-textarea>
                  <span v-if="allerros.description" :class="['label label-danger']">@{{ allerros.description[0] }}</span>
                </b-form-group>
                <b-form-group label="File" label-for="code">
                <b-form-file
                  placeholder="Choose a file"
                  id="customFile"
                  v-model="items.file"
                ></b-form-file>
              </b-form-group>
                </b-col>
                <b-col md="6">
              <b-form-group>
                <label for="usertype">Device type</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.device_type" :options="deviceType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Device Type</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
                <b-form-group label="cap" label-for="code">
                  <b-form-input id="cap" type="text" placeholder="Caps" v-model="items.cap"></b-form-input>
                  <span v-if="allerros.cap" :class="['label label-danger']">@{{ allerros.cap[0] }}</span>
                </b-form-group>
                <b-form-group>
                <label for="usertype">Conversion Flow</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.conv_flow_id" :options="convFlow" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Flow</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
              <b-form-group>
                <label for="usertype">Pricing Model</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.pricing_model" :options="pricingModels" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
              <b-form-group label="Notes" label-for="note">
                  <b-form-textarea id="note" rows="4" placeholder="Notes" v-model="items.note"></b-form-textarea>
                  <span v-if="allerros.note" :class="['label label-danger']">@{{ allerros.note[0] }}</span>
                </b-form-group>
                <b-form-group label="Expired_at" label-for="code">
                  <b-form-input id="exampleInputdate" type="date" v-model="items.expire_date"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
                <b-button type="submit" variant="primary">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
                <div>Total Rows : {{ totalRows}}</div>
              </b-col>
                <b-col sm="0" md="3" class="my-1" style="float: right;">
        <b-form-group
          label="Page"
          label-for="per-page-select"
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            @change="handleFilterBlur"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
                <b-table bordered hover 
                :items="u_offer_rows" 
                :fields="columns"  
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                >
                <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key" >
                    <b-form-input  v-model="filters[field.key]" :placeholder="field.label" @change="handleFilterBlur" v-if="filters.hasOwnProperty(field.key)"></b-form-input>
                  </td>
                </template>

                <template v-slot:cell(int_id)="data">
                    <span v-if="!data.item.editable">{{ data.item.int_id }}</span>
                    <input type="text" v-model="data.item.int_id" v-else class="form-control">
                  </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                  </template>
                  <template v-slot:cell(price)="data">
                    <span v-if="!data.item.editable">{{ data.item.price }}</span>
                    <input type="text" v-model="data.item.price" v-else class="form-control">
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0" title="View"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
          <b-col sm="3" md="2" class="my-1" v-if="u_offer_rows.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Previous"
          next-text="Next"
          first-text="First"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Offer from '../../Apis/offer'
import Common from '../../Apis/common'
import Loader from '../../components/Loader.vue'

export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
  },
  components: {
    Loader
  },
  data () {
    return {
      columns: [
        { label: 'Int_id', key: 'int_id', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Expired_At', key: 'expire_date', class: 'text-left'},
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      u_offer_rows: [],
      items: {
        _id:'',
        name: '',
        description: '',
        url: '',
        price: '',
        pricing_model:null,
        cap:'',
        device_type :null,
        conv_flow_id:null,
        note:'',
        active:null,
        expire_date:''
        },
      filters: {
        name: '',
        price: '',
        int_id:'',
      },
      allerros:{},
      deviceType:[],
      convFlow:[],
      active_status:[],
      pricingModels:[],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loadingData:true
    }
  },
  methods: {
    default () {
      return {
        items:{
          _id:'',
        name: '',
        description: '',
        url: '',
        price: '',
        pricing_model:null,
        cap:'',
        device_type :null,
        conv_flow_id:null,
        note:'',
        active:null,
        expire_date:''
        },
        editable: false
      }
    },
    handleFilterBlur() {
      this.currentPage = 1; // Reset the current page when filtering
        this.listExpiredOffer(); // Call listCountry when filter input is blurred
      },
    edit(item) {
      this.items = item;
      this.allerros={};
      this.$refs.myModalRef.show();
    },
    showModal(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    submit (item) {
      this.items = item;
      this.submitForm();
      item.editable = false
    },
    async submitForm(){
      this.loadingData = true;
      try{
        let response = await Offer.storeOffer(this.items)
        if(response.status == 201){
          this.$toast.info('Successfully Updated Expired Offers!', {
                    position: 'top-center'
                });
          // console.log(response.message);
          this.listExpiredOffer()
          this.items = this.default()
          this.$refs.myModalRef.hide();
        }else{
          // console.log(response);
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listExpiredOffer(){
      this.loadingData = true;
      try{
        const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
        let response = await Offer.listExpiredOffer(params)
        if(response.status == 200){
          let data = response.data.offers.data
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
          });
          this.u_offer_rows = data
          this.totalRows =response.data.offers.total
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listStatus(){
      try{
        let response = await Common.listStatus()
        if(response.status == 200){
          let p_model = response.data.pricing_model
          let d_type = response.data.device_type
          let a_status = response.data.offer_status
          
          //pricing model
          for(const key in p_model){
            this.pricingModels.push({ value: key, text: p_model[key] })
          }

          //device type
          for(const key in d_type){
            this.deviceType.push({ value: key, text: d_type[key] })
          }

          //active status
          for(const key in a_status){
            this.active_status.push({ value: key, text: a_status[key] })
          }
        }
      }catch(error){
      }
    },
    setNext10YearsDate() {
      const today = new Date();
      const next10Years = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate());
      this.items.expire_date = next10Years.toISOString().substr(0, 10);
    },
    async activeConvFlow(){
      try{
        let response = await Offer.activeConvFlow()
        if(response.status == 200){
          let data = response.data.convflows
          //store value as a _id and text as a name
          for(const key in data){
            this.convFlow.push({ value: data[key]._id, text: data[key].name })
          }
        }
      }catch(error){
      }
    },
  },
  created(){
    this.listExpiredOffer()
    this.listStatus()
    this.activeConvFlow()
    this.setNext10YearsDate()
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listExpiredOffer',
  },
  computed: {
  }
}
</script>
