<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Domain List</h4>
          </template>
          <template v-slot:headerAction>
            <b-button  @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Add Domain" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <div class="form-group">
                <label for="name">Name</label><span class="text-danger"> *</span>
                <input type="text" class="form-control mb-0" id="name" aria-describedby="emailHelp" v-model="items.name" placeholder="Enter Name">
              </div>
              <div class="form-group">
                <label for="domain">Domain</label><span class="text-danger"> *</span>
                <input type="text" class="form-control mb-0"  v-model="items.domain" aria-describedby="emailHelp" placeholder="Enter Domain">
                </div>
              <b-form-group>
               
                <label for="">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.status" :options="domainStatus" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
                   <br/>
              <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          @change="handleFilterBlur"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
    
              <b-table bordered hover 
              :items="user_rows" 
              :fields="columns"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                    </template>
                  <template v-slot:cell(domain)="data">
                    <span v-if="!data.item.editable">{{ data.item.domain }}</span>
                    <input type="text" v-model="data.item.domain" v-else class="form-control">
                  </template>
                  <template v-slot:cell(status)="data">
                    <b-button variant=" iq-bg-danger" @click="(isAdmin || isRights.includes('Domain_Approved')) && update(data.item)" v-if="data.item.status_text == 'Inactive'" size="sm">{{ data.item.status_text}}</b-button>
                    <b-button v-else-if ='data.item.status == 3' @click="(isAdmin || isRights.includes('Domain_Approved')) && update(data.item)">{{ data.item.status_text}}</b-button>
                    <b-button variant=" iq-bg-success" @click="(isAdmin || isRights.includes('Domain_Approved')) && update(data.item)" v-else  size="sm">{{ data.item.status_text}}</b-button>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <b-col sm="3" md="2" class="my-1" v-if="user_rows.length > 0">
            <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Previous"
          next-text="Next"
          first-text="First"
          last-text="Last"
        ></b-pagination>
    </b-col>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
 
import Offer from '../../Apis/offer'
import Loader from '../../components/Loader.vue'
import Multiselect from 'vue-multiselect'
import { sofbox } from '../../config/pluginInit'
import Common from '../../Apis/common'
import offer from '../../Apis/offer'

  
  export default {
    name: 'UploadDomainOffer',

    mounted () {
    sofbox.index(),
    this.loadData()
  },
    components: {
    'multiselect':Multiselect,Loader
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listDomain',
  },
    methods: { 
    default () {
      return {
        items:{
        name:'',
        domain: ''
        },
        editable: false,
        allerros:{}
      }
    },
      showModal(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    edit(item) {
      if(this.editable_master == true){
          return false;
        }
        this.editable_master = true;
      item.editable = true
      this.allerros = {}  
        this.user_rows.forEach((element) => {
          if(element._id != item._id){
            element.editable = false
          }
        });
    },
    submit (item) {
      this.items = item;
      this.submitForm();
    },
      async submitForm(){
        try{
            let domainsArray = this.items.domain.split(',').map(domain => domain.trim());
            this.errorMessage = '';
           let userId = this.login_id;
           this.items.userId = userId;
           this.items.domain = domainsArray;
           
          let response = await Offer.storeDomainOffer(this.items)
          let message = response.data.message;
          if(response.status == 201){
          this.$toast.info(message, {
                    position: 'top-center'
                })
        }
        this.listDomain()
        this.default()
          this.items = {};
          this.allerros={}
          this.editable_master=false;
          this.loadingData = false;
      }catch(error){
         console.error('Error:', error);
        this.errorMessage = 'The Domain already exists.';
      }
      },
      async update(item) {
      this.loadingData = true;
      this.items._id = item._id;
      this.items.status = item.status;
      try{
        let response = await Offer.updateDomainStatus(this.items)
        if(response.status == 200){
            this.$toast.info('Domain Upload Successfully!', {
                    position: 'top-center'
                })
          this.listDomain()
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
      async remove (item) {
      if(confirm('Are you sure to delete the user?')){
        try{
          let response = await offer.deleteDomain(item)
          if(response.status == 200){
            this.listDomain()
            this.default()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    setRole(){
      const user = localStorage.getItem('user')
      if (user) {
        let role = JSON.parse(user)
        this.isRights = role.rights ;
        this.isAdmin = role.role == 'admin'
        this.isManager = role.role == 'manager'
        this.login_id = role._id
      }
    },
      handleFilterBlur() {
      this.currentPage = 1; // Reset the current page when filtering
        // this.listDomain(); 
      },

      async listDomain(){
        const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
      try{
        let response = await Offer.listDomain(params)
        if(response.status == 200){
          this.default()
          let data = response.data.data;
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
            const status_active = this.domainStatus.find(x => x.value == element.status);
            element.status_text = status_active ? status_active.text : '';

             if (Array.isArray(element.domain)) {
          element.domain = element.domain.join(', ');
        }
          });

        this.user_rows = data
        this.totalRows = response.data.total
       
        }
        this.loadingData = false;
      }catch(error){
        console.log(error)
        this.loadingData = false;
      }
    },
    async getUserStatus(){
      try {
        let response = await Common.listStatus()
        let domainstatus = response.data.domain_status;
        
        for (const key in domainstatus) {
          this.domainStatus.push({ value: key, text: domainstatus[key] });
        }
      } catch (e) {
        console.log(e)
      }
    },
      async loadData() {
      try {
        await this.setRole()
        await this.getUserStatus()
        await this.listDomain()

      } catch (error) {
        console.error('Error loading data:', error);
      }
    }
    },
    data () {
    return {
      columns: [
       { key: 'name', label: 'Name', sortable: true },
       { key: 'domain', label: 'Domain', sortable: true },
       { key: 'status', label: 'Status'},
       { key: 'action', label: 'Action'},
      ],
      items:{
        name: '',
        domain: ''
       },
       domainStatus: [],
       user_rows: [],
       totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      editable_master:false,
      allerros:{},
    }
  },
    computed: {
    allFieldsValid() {
      return this.items.name && this.items.domain;
   }
  },

}
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style>
  .filter:focus-within .multiselect__content-wrapper {
      width: 300px!important;
  }
  </style>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style scoped>
  .table-responsive{
    overflow-x:unset !important ;
  }
  </style>
  
  