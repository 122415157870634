<template>
  <router-view/>
</template>
<script>
import UserControl from '../src/Apis/usercontrol'
export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.setRole();
    if (localStorage.getItem('activityCheckActive') === 'true') {
      this.startActivityCheck();
    }
    this.$watch('$route', () => {
        this.updateActivity(); // Call updateActivity on route change
    });
  },
  data () {
    return {
      login_id: '',
    }
  },
  methods: {
    startActivityCheck() {
      localStorage.setItem('activityCheckActive', 'true');

     this.activityCheckInterval = setInterval(() => {
        this.checkActivity();
       }, 120000); // 120000 milliseconds = 2 minutes
     },
     async updateActivity() {
    if (!localStorage.getItem('user')) {
      return;
    }
    const params = {
        update: true,
        _id: this.login_id
      };
    try {
      await UserControl.activity(params); // Call API to update last activity
    } catch (error) {
      console.error('Failed to update activity', error);
    }
  },

     async checkActivity(){
      if (!localStorage.getItem('user')) {
         return;
      }
      const params = {
        update: false,
        _id: this.login_id
      };
      let response = await UserControl.activity(params);
      if(response.data.loggedOut == true){
        localStorage.removeItem('user')
        localStorage.setItem('activityCheckActive', 'false');
        UserControl.logout()
        this.$router.push({ name: 'auth1.login' })
      }
     },
     setRole(){
      const user = localStorage.getItem('user')
      if (user) {
        let role = JSON.parse(user)
        this.login_id=role._id
      }
    },
  }

}
</script>
<style lang="scss">
  @import "./src/assets/scss/style.scss";

.form-control {
  background: #fff !important;
    border: 1px solid #d7dbda !important;
}
body{
  font-size: 13px !important;
  color: #777D74 !important;
}
.multiselect__option {
  width: 100%; 
}
.res_text {
  display: inline-block;
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  cursor: pointer;
}
.res_text:hover {
  max-width: auto;
  text-overflow: clip;
  white-space: normal;
}
</style>
